<template>
  <div>
    <div class="card">
      <div class="card-body">
        <h5 class="mb-4">
          <strong>Detail Laboratorium</strong>
        </h5>
        <a-spin v-if="isLoading" />
        <a-form :form="form"  @submit="handleSubmit"  v-if="!isLoading">
          <a-form-item :label-col="labelCol" :wrapper-col="wrapperCol" label="Nama Laboratorium">
            <a-input
              :disabled="submitting"
              placeholder="Nama Laboratorium"
              v-decorator="['name', {rules: [{ required: true, message: 'Nama laboratorium harus diisi!' }]}]"
            />
          </a-form-item>
          <!-- <a-form-item :label-col="labelCol" :wrapper-col="wrapperCol" label="Nomor KTP">
            <a-input
              :disabled="submitting"
              placeholder="Nomor KTP"
              v-decorator="['idNumber', { rules: [{required: true, len: 16, message: 'Nomor KTP harus 16 digit!' }]}]"
            />
          </a-form-item> -->
          <a-form-item :label-col="labelCol" :wrapper-col="wrapperCol" label="Alamat Laboratorium">
            <a-input
              :disabled="submitting"
              placeholder="Alamat Laboratorium"
              v-decorator="['address', {rules: [{ required: true, message: 'Alamat laboratorium harus diisi!' }]}]"
            />
          </a-form-item>
          <!-- <a-form-item :label-col="labelCol" :wrapper-col="wrapperCol" label="Rumah Sakit">
            <a-input
              :disabled="submitting"
              placeholder="Rumah Sakit"
              v-decorator="['hospital', {rules: [{ required: true, message: 'Nama rumah sakit harus diisi!' }]}]"
            />
          </a-form-item>
          <a-form-item :label-col="labelCol" :wrapper-col="wrapperCol" label="Kota">
            <a-select
              :disabled="submitting"
              v-decorator="[
                'city',
                { rules: [{ required: true, message: 'Kota harus diisi!' }] },
              ]"
              placeholder="Kota"
            >
              <a-select-option v-for="city in cityList" :key="city.id" :value="city.name">
                  {{city.name}}
               </a-select-option>
            </a-select>
          </a-form-item>
          <a-form-item :label-col="labelCol" :wrapper-col="wrapperCol" label="Kode Verifikasi">
            <a-input
              :disabled="submitting"
              placeholder="Kode Verifikasi"
              v-decorator="['verificationCode', {rules: [{required: true, len: 6, message: 'Kode verifikasi harus 6 karakter!' }]}]"
            />
          </a-form-item> -->
          <button type="submit" class="btn btn-success px-5" :disabled="submitting">Simpan Data</button>
          <button class="btn btn-danger px-5 m-1" :disabled="submitting" @click="(e)=>{e.preventDefault(); setModalVisible(true)}">Hapus Data</button>
          <button class="btn btn-secondary px-5" :disabled="submitting" @click="handleCancel">Kembali</button>
        </a-form>
      </div>
    </div>
    <a-modal
      title="Konfirmasi"
      :dialog-style="{ top: '20px' }"
      :visible="modalVisible"
      @ok="() => handleDelete()"
      @cancel="() => setModalVisible(false)"
    >
      Apakah Anda yakin akan menghapus data laboratorium {{form.getFieldValue("name")}}?
    </a-modal>
  </div>
</template>
<script>

import router from '@/router'
import { getById, editById, deleteById } from '@/services/axios/api/laboratorium'
import { getAll } from '@/services/axios/api/city'
import { notification } from 'ant-design-vue'

const labelCol = {
  xs: { span: 24 },
  sm: { span: 4 },
}
const wrapperCol = {
  xs: { span: 24 },
  sm: { span: 12 },
}
export default {
  data: function () {
    return {
      form: this.$form.createForm(this),
      labelCol,
      wrapperCol,
      cityList: [],
      isLoading: true,
      submitting: false,
      modalVisible: false,
    }
  },
  created() {
    this.getDoctorById()
    this.getCityList()
  },
  methods: {
    async getDoctorById() {
      // init form field
      this.form.getFieldDecorator('name', { initialValue: '' })
      this.form.getFieldDecorator('address', { initialValue: '' })
      // this.form.getFieldDecorator('city', { initialValue: '' })
      // this.form.getFieldDecorator('hospital', { initialValue: '' })
      // this.form.getFieldDecorator('verificationCode', { initialValue: '' })
      // this.form.getFieldDecorator('idNumber', { initialValue: '' })

      // get data
      const res = await getById(this.$route.params.id)

      // set loading false
      this.isLoading = false

      if (res) {
        // set form data
        this.form.setFieldsValue({ name: res.name })
        this.form.setFieldsValue({ address: res.address })
        // this.form.setFieldsValue({ city: res.city })
        // this.form.setFieldsValue({ hospital: res.hospital })
        // this.form.setFieldsValue({ verificationCode: res.verificationCode })
        // this.form.setFieldsValue({ idNumber: res.idNumber })
      }
    },
    async getCityList() {
      const res = await getAll()
      this.cityList = res
    },
    handleSubmit(e) {
      e.preventDefault()
      this.form.validateFields(async (err, values) => {
        if (!err) {
          this.submitting = true
          console.log('Received values of form: ', values)
          try {
            const res = await editById(this.$route.params.id, values)
            if (res && res.data) {
              notification.success({
                message: 'Sukses',
                description: 'Data berhasil disimpan',
                duration: 5,
              })
              router.push('/laboratorium')
            }
            this.submitting = false
          } catch (e) {
            this.submitting = false
          }
        }
      })
    },
    async handleDelete() {
      const res = await deleteById(this.$route.params.id)
      if (res && res.data) {
        notification.success({
          message: 'Sukses',
          description: 'Data berhasil dihapus',
          duration: 5,
        })
        router.push('/laboratorium')
      } else {
        notification.warning({
          message: 'Gagal',
          description: 'Data gagal dihapus',
          duration: 5,
        })
      }
      this.submitting = false
      this.modalVisible = false
    },
    handleCancel(e) {
      e.preventDefault()
      router.push('/laboratorium')
    },
    setModalVisible(modalVisible) {
      this.modalVisible = modalVisible
    },
  },
}
</script>
